import React from 'react';

import { Section, Text } from '@utils';
import SEO from '@component/SEO';

const Terms: React.FC = () => {
  return (
    <>
      <SEO
        path="/terms"
        title="Terms of Service"
        description="At Coral Dev, we operate on a fixed set of rules. These are our terms of service and they must be reviewed and accepted by all clients looking to work with us at Coral Dev."
      />
      <Section id="privacy">
        <div className="lg:max-w-5xl lg:mx-auto space-y-10">
          <Text style="h2" tag="h1">
            Terms of Service | 5/1/21
          </Text>
          <Text>
            Do ensure that you read this term of service agreement carefully; by
            using our website, making contact with us by either E-Mail or
            Discord and or via our website, or making a purchase and or paying
            an invoice, you agree to be bound by the terms and conditions of
            this agreement in full, without objection. The terms you agree to be
            bound by are thereof:
          </Text>
          <ol className="space-y-10 list-decimal">
            <li>
              <Text>
                This terms of service agreement (this “Agreement”) is a legally
                binding agreement between you (“Customer, Client, User”) and
                Syncore LLC (“Us, We, Our”); this agreement shall govern your
                rights, use, and obligations during the course of business with
                us and the subsequent termination thereof.
              </Text>
            </li>
            <li>
              <Text>
                We offer digital content related to “online video game” content;
                as such, the industry-standard applies shall include digital
                products such as plugins, designs, and other virtual products
                that are based on specific platforms and mediums and or
                “Websites and Online Stores.”
              </Text>
            </li>
            <li>
              <Text>
                You understand that the content is digital and, as such, you
                shall not receive any physical copy of the content and or
                product thereof; the content shall be virtual, nor shall any
                shipping to your physical address take place. You shall receive
                a downloadable file where your IP, download timestamp, and
                identity to download such file will be recorded and used as
                proof of delivery thereof and or if any dispute occurs.
              </Text>
            </li>
            <li>
              <Text>
                You agree that during the discussion with us, you will clearly
                outline your requirements, description of the digital content
                you require and give a full and complete outline thereof; you
                agree that in the event you exclude any details and we do not
                incorporate such in your digital content we shall not be held
                liable for your failure to include such details.
              </Text>
            </li>
            <li>
              <Text>
                You agree that an invoice shall be generated with the details
                you have provided in terms of clause (4) above, thus where you
                have failed to include any details, you shall be liable to pay
                the extra costs thereof and or we hold the right to request
                additional payment, where you have failed to provide adequate
                details you cannot hold us liable to incorporate such without
                costs nor hold us liable to do such free of charge.
              </Text>
            </li>
            <li>
              <Text>
                You agree and understand that any payments that are made to us
                are NON-REFUNDABLE under any circumstances; the payment shall
                ONLY be refunded by us if we elect to do such and or where we
                have not yet started the project; furthermore, you agree not to
                open any disputes with your payment provider, financial
                institution and or the payment gateway. If you decide to open
                such dispute, you agree that we hold the right to defend such
                dispute and we hold the right to appoint an attorney of our
                choice to fight and or settle the dispute in full and as such
                the costs shall be your responsibility thereof, further you
                agree we hold the right to appoint a debt collector, and you
                shall be liable for the costs thereof; furthermore, we hold the
                right to open a case with a local police authority for attempt
                of fraud and/or malicious intent to commit fraud.
              </Text>
            </li>
            <li>
              <Text>
                We agree that the services which are to be provided (the digital
                content) shall be of the highest standard and shall fulfill the
                details you have provided in terms of clause (4) of this
                agreement in full, where such does not meet the full high
                standard, we shall ensure such revisions are done to achieve the
                standard required. 8. You agree that if you require additional
                services on any given digital content thereof shall be done for
                an extra charge, if such is not included within your initial
                description and that we have the right to charge for such.
              </Text>
            </li>
            <li>
              <Text>
                You agree that the intellectual property rights of the digital
                content shall only transfer to you once the final payment has
                been made and the final payment has been received in full. If
                you open a dispute in terms of clause (6) the intellectual
                property rights shall revert to us, pending the outcome of the
                dispute.
              </Text>
            </li>
            <li>
              <Text>
                You agree to indemnify us and our affiliates and hold us
                harmless against legal claims and demands that may arise from
                your use or misuse of our services. We reserve the right to
                select our legal counsel.
              </Text>
            </li>
            <li>
              <Text>
                You agree that we hold a limited liability as such, we shall not
                be liable for any damages the digital content causes and or
                creates as such. We shall not be responsible for any damages,
                special damages, and or punitive damages thereof and or not
                limited to loss of profit, damages caused to hardware and or
                software, and or any damages thereof.
              </Text>
            </li>
            <li>
              <Text>
                ANY AND ALL DIGITAL CONTENT WE PROVIDE IS PROVIDED ON A “AS IS."
                “AS AVAILABLE” BASIS, WITHOUT WARRANTY TO THE EFFECTIVENESS
                THEREOF AND OR WITHOUT REPRESENTATIONS OF ANY KIND. WE HEREBY
                DISCLAIM ANY AND ALL CLAIMS THAT WE INSINUATE, WE IMPLIED AND OR
                PROMISED IN REGARDS TO THE EFFECTIVENESS AND OR WARRANTY OF THE
                DIGITAL CONTENT.
              </Text>
            </li>
            <li>
              <Text>
                You agree that the digital content we have created for you is
                used at your own risk and not at risk of ours; furthermore, you
                agree that the use of the digital content by yourself and or
                your affiliates are used for lawful purposes, only as such, we
                shall not and will not be held liable for your actions and or
                any consequences thereof.
              </Text>
            </li>
            <li>
              <Text>
                We do not grant a license to use the digital content, once
                payment has been made and the product is fully delivered
                thereof, the digital content is yours to own in full, and we
                hold no ownership of the digital content thereof; however, you
                agree that we are allowed to repurpose any of the tools, usage,
                source code, resources and or any applicable requirements and or
                needs we utilized to create the digital content for our future
                services.
              </Text>
            </li>
            <li>
              <Text>
                You can terminate this agreement at any time with written notice
                to us; given that such notice is sent 14 days in advance of the
                termination, where you have made a payment and wishes to
                terminate the agreement, you agree that your payments shall not
                be refundable.
              </Text>
            </li>
            <li>
              <Text>
                In the event where we’ve started any work and have not yet
                accepted or received any payment from you, you agree that you
                are responsible for such payment in FULL and will provide such
                payment in FULL.
              </Text>
            </li>
            <li>
              <Text>
                The client hereby agrees to abide by the doctrine of
                "non-solicitation" which in the context of this agreement shall
                be that; where the client expressly agrees not to approach,
                communicate and or hire any of our (Coral Dev) employees,
                contractors, and or freelancers for any services either with
                direct contact with or through us and or independently that is
                either concurrent with the current project, new projects and or
                future projects.
              </Text>
            </li>
            <li>
              <Text>
                The client hereby agree to abide by the doctrine of
                "non-solicitation" which in context of this agreement shall be
                that; where the client expressly agree not to approach,
                communicate and or hire any of our (Coral Dev) employees,
                contractors and or freelancers for any services either with
                direct contact with or through us and or independently that is
                either concurrent with the current project, new projects and or
                future projects.
              </Text>
            </li>
          </ol>
        </div>
      </Section>
    </>
  );
};

export default Terms;
